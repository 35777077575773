import cn from "classnames"
import React, { FC, useEffect } from "react"

import CrossIcon from "../../images/icons/cross.inline.svg"
import SearchInput from "../search-input"
import { ISearchBar } from "./search-bar-menu.props"

import { cssNavbar } from "../layout/header/header.module.scss"
import * as styles from "./search-bar-menu.module.scss"

const SearchBarMenu: FC<ISearchBar> = ({ toggleSearchBar, isSearchBarVisible }) => {
  const onCloseSearchInput = () => {
    toggleSearchBar()
  }

  useEffect(() => {
    // [#6356] Close searchBarMenu when escape is pressed
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isSearchBarVisible) {
        toggleSearchBar()
        document.getElementById("toggle-main-menu")?.focus()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isSearchBarVisible])

  return (
    <div className={cn("search-bar-container", styles.searchBarContainer)}>
      <div
        className={cn(
          cssNavbar,
          isSearchBarVisible ? "search-bar-container-transition" : "none search-bar-container-transition"
        )}
      >
        <div className="close-button-container">
          <button
            className="close-button"
            id="close"
            onClick={toggleSearchBar}
            onKeyUp={(event) => {
              console.log(event.key)
              if (event.key === "Enter" || event.key === " ") {
                window.setTimeout(() => {
                  const searchBtn = document.getElementById("button-search")
                  if (searchBtn) {
                    searchBtn.focus()
                  }
                }, 80)
              }
            }}
          >
            <span className="sr-only">Suche schliessen</span>
            <CrossIcon className={styles.crossIcon} />
          </button>
        </div>
        <SearchInput
          onClickSuggestion={onCloseSearchInput}
          inputID={"search-bar-input-menu-header"}
          inputLinkID={"search-link-header"}
          isSearchBarVisible={isSearchBarVisible}
        />
      </div>
    </div>
  )
}

export default SearchBarMenu
